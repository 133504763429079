import { ModalState } from './components/Modal';
import { atom } from 'recoil';

const modalState = atom({
	key: 'modalState',
	default: {
		email: { visible: false, state: ModalState.SUCCESS },
	},
});

export { modalState };
