import React, { ReactNode } from 'react';
type ContentFulSectionProps = {
	title: string;
	children: ReactNode;
	slug: string;
};
const ContentFulSection = ({
	title,
	children,
	slug,
}: ContentFulSectionProps) => {
	return (
		<section
			id={slug}
			className={`${slug} pb-5`}
			data-sal='slide-down'
			data-sal-delay='500'
			data-sal-easing='ease'
		>
			<h2 className='mb-12 text-xs uppercase text-slate-300 '>
				<span className='ml-20'>{title}</span>
			</h2>
			<div className='p-10 rounded-3xl bg-slate-400/10'>{children}</div>
		</section>
	);
};

export default ContentFulSection;
