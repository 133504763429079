import React, { ReactNode } from 'react';
export type ContentFulLinkProps = {
	href: string;
	title?: string;
	children: ReactNode;
	className?: string;
};
const ContentFulLink = ({ href, className, children }: ContentFulLinkProps) => {
	return (
		// <div className={className}>
		<a
			target='_blank'
			className={className}
			href={href}
			rel='noopener noreferrer'
		>
			{children}
		</a>
		// </div>
	);
};

export default ContentFulLink;
