import { HeadFC, graphql } from 'gatsby';
import { IntlShape, injectIntl } from 'gatsby-plugin-intl-v4';

import ContentFulPage from './ContentFulPage';
import Layout from './Layout';
import React from 'react';
import { SEO } from './SEO';

type Page = {
	pageContext: any;
	intl: IntlShape;
	data: {
		allContentfulNavigation: {
			edges: [
				{
					node: any;
				}
			];
		};
		allContentfulPage: {
			edges: [
				{
					node: any;
				}
			];
		};
	};
};
const Page = ({ pageContext, data }: Page) => {
	return (
		<Layout
			edges={data.allContentfulNavigation.edges}
			languages={pageContext.slugs}
		>
			<ContentFulPage edges={data.allContentfulPage.edges} />
		</Layout>
	);
};

export const query = graphql`
	query LayoutPageQuery($node_locale: String) {
		allContentfulNavigation(filter: { node_locale: { eq: $node_locale } }) {
			edges {
				node {
					items {
						title
						icon {
							source {
								file {
									url
								}
								title
							}
						}
						href
					}
				}
			}
		}
		allContentfulPage(filter: { node_locale: { eq: $node_locale } }) {
			edges {
				node {
					sys {
						contentType {
							sys {
								id
							}
						}
					}
					content {
						sys {
							contentType {
								sys {
									id
								}
							}
						}
						slug
						title
						content {
							... on ContentfulMedia {
								sys {
									contentType {
										sys {
											id
										}
									}
								}
								source {
									gatsbyImageData(
										layout: CONSTRAINED
										placeholder: BLURRED
										width: 350
									)
									title
									file {
										url
										contentType
										fileName
									}
								}
							}
							... on ContentfulParagraphe {
								title
								slug
								content {
									raw
								}
								sys {
									contentType {
										sys {
											id
										}
									}
								}
							}
							... on ContentfulLink {
								href
								title
								sys {
									contentType {
										sys {
											id
										}
									}
								}
							}
							... on ContentfulProjects {
								title
								features
								link {
									href
									title
								}
								screenshot {
									gatsbyImageData(
										layout: CONSTRAINED
										placeholder: BLURRED
										width: 350
										height: 200
									)
									filename
								}
								technologies {
									title
								}
								sys {
									contentType {
										sys {
											id
										}
									}
								}
							}
							... on ContentfulSkills {
								sys {
									contentType {
										sys {
											id
										}
									}
								}
								skillContent: content {
									id
									title
									icon {
										file {
											url
										}
									}
									rating
								}
							}
							... on ContentfulForm {
								sys {
									contentType {
										sys {
											id
										}
									}
								}
								items {
									isRequired
									label
									placeholder
									slug
									type
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default injectIntl(Page);

export const Head: HeadFC = ({ pageContext }: any) => (
	<SEO
		title={pageContext.title}
		description={pageContext.description.description}
		pathname={pageContext.slug}
	/>
);
