import { FormattedMessage, IntlShape, injectIntl } from 'gatsby-plugin-intl-v4';

import ContentFulLink from './ContentFulLink';
import ContentFulMedia from './ContentFulMedia';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const PresentationSection = ({ content, slug }) => {
	const imageNodes = content.filter(
		(item) => item.sys.contentType.sys.id === 'image'
	);
	const hiThereNode = content
		.filter((item) => item.slug === 'hi-there')
		.shift();
	const descriptionOfMeNode = content
		.filter((item) => item.slug === 'description-of-me')
		.shift();

	const linksNode = content.filter(
		(item) => item.sys.contentType.sys.id === 'links'
	);
	return (
		<section
			id={slug}
			data-sal='slide-left'
			data-sal-delay='500'
			data-sal-easing='ease'
			className='grid grid-cols-12 mb-16 xl:h-screen presentation'
		>
			<div className='flex flex-col justify-center col-span-12 xl:col-span-6'>
				<div>
					<ContentFulMedia
						source={imageNodes[0].source}
						className='flex items-center justify-center max-w-xs m-auto overflow-hidden rounded-full presentation-image w-80 h-80'
					/>
				</div>
			</div>
			<div className='flex flex-col justify-center col-span-12 xl:col-span-6'>
				<p className='uppercase text-slate-100'>{hiThereNode.title}</p>
				<h1 className='mt-4 mb-4'>
					<strong className='uppercase text-sky-500'>Gian Matteo</strong>{' '}
					<span className='text-white uppercase'>Spoto</span>
				</h1>
				<div className='mb-10 text-slate-300'>
					{renderRichText(descriptionOfMeNode.content)}
				</div>
				<div className='flex flex-row items-center justify-between'>
					<ContentFulLink
						href={imageNodes[1].source.file.url}
						className='flex items-center pt-2 pb-2 pl-8 pr-8 font-medium rounded-full hover:shadow-lg hover:shadow-sky-500/50 bg-sky-400/10 p- group lg:text-sm lg:leading-6 text-slate-400 hover:text-slate-300 hover:bg-sky-500'
					>
						<FormattedMessage id='resume' />
					</ContentFulLink>
					<ul className='flex flex-row'>
						{linksNode.map((link) => (
							<li key={link.title} className='mr-4 '>
								<ContentFulLink
									href={link.href}
									className='block p-1 rounded-md hover:shadow-lg bg-sky-400/10 hover:shadow-sky-500/50'
								>
									{link.title === 'Github' && (
										<StaticImage
											className='w-6'
											src='../images/github-icon.png'
											placeholder='blurred'
											alt={link.title}
											transformOptions={{
												fit: 'cover',
												cropFocus: 'attention',
											}}
										/>
									)}
									{link.title === 'LikendIn' && (
										<StaticImage
											className='w-6'
											src='../images/linkedin-icon.png'
											placeholder='blurred'
											alt={link.title}
											transformOptions={{
												fit: 'cover',
												cropFocus: 'attention',
											}}
										/>
									)}
								</ContentFulLink>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default injectIntl(PresentationSection);
