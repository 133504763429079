import ContentFulForm from './ContentFulForm';
import ContentFulLink from './ContentFulLink';
import ContentFulMedia from './ContentFulMedia';
import ContentFulParagraphe from './ContentFulParagraphe';
import ContentFulProject from './ContentFulProject';
import ContentFulSection from './ContentFulSection';
import ContentFulSkill from './ContentFulSkill';
import PresentationSection from './PresentationSection';
import React from 'react';

type ContentFulPageProps = {
	edges: [{ node: any }];
};
const ContentFulPage = ({ edges }: ContentFulPageProps) => {
	const renderWidget = (node: any) => {
		const widgetType = node.sys ? node.sys.contentType.sys.id : null;
		if (widgetType === 'section') {
			if (node.slug === 'presentation') {
				return <PresentationSection slug={node.slug} content={node.content} />;
			}
			return (
				<ContentFulSection title={node.title} slug={node.slug}>
					{renderWidget(node.content)}
				</ContentFulSection>
			);
		} else if (widgetType === 'paragraphe') {
			return <ContentFulParagraphe content={node.content} title={node.title} />;
		} else if (widgetType === 'image') {
			return <ContentFulMedia source={node.source} />;
		} else if (widgetType === 'links') {
			return <ContentFulLink href={node.href}>{node.title}</ContentFulLink>;
		} else if ((node.content && node.content.length) || node.length) {
			const contentItems = node.content || node;
			return contentItems.map((contentItem) => renderWidget(contentItem));
		} else if (widgetType === 'projetcs') {
			return <ContentFulProject content={node} />;
		} else if (widgetType === 'skills') {
			return <ContentFulSkill content={node.skillContent} />;
		} else if (widgetType === 'form') {
			return <ContentFulForm items={node.items} />;
		}
	};
	return <>{edges.map((edge) => renderWidget(edge.node))}</>;
};

export default ContentFulPage;
