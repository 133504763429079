import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useWindowSize } from '../hooks/use-window-resize';

type SideBarProps = {
	edges: {
		node: {
			items: [
				{
					title: string;
					href: string;
					icon: {
						source: {
							file: {
								url: string;
							};
							title: string;
						};
					};
				}
			];
		};
	}[];
};

const SideBar = ({ edges }: SideBarProps) => {
	const [hasSidebar, setHasSidebar] = useState(false);
	const size = useWindowSize();
	useEffect(() => {
		if (size.width! < 768) setHasSidebar(false);
	}, []);

	const handleClick = () => {
		setHasSidebar(!hasSidebar);
	};

	return (
		<>
			<button
				className='fixed visible right-4 top-8 md:invisible'
				aria-label='Menu'
				onClick={handleClick}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth={1.5}
					stroke='currentColor'
					className='w-10 h-10'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5'
					/>
				</svg>
			</button>
			<aside
				className={`fixed  z-10 ${
					hasSidebar || size.width! > 768 ? 'left-0 md:left-0' : '-left-56'
				} h-full duration-200 ease-in md:w-28 w-56 sidebar transition-all bg-sky-500 hover:w-56`}
			>
				<p className='pt-8 pb-8 pl-4 pr-4 text-5xl text-center bg-sky-600 text-slate-100'>
					Gs
				</p>

				<nav
					className='flex flex-col justify-center h-full'
					style={{ height: 'calc(100% - 112px)' }}
				>
					<ul>
						{edges[0].node.items.map((edge) => (
							<li key={edge.title} className='p-2'>
								<a
									href={edge.href}
									onClick={handleClick}
									className='flex flex-row items-center pt-2 pb-4 pl-8 pr-4 transition ease-linear delay-75 text-slate-100 hover:rounded-lg hover:bg-sky-400'
								>
									<img
										src={edge.icon.source.file.url}
										className='w-6 h-6 mr-2 '
										alt={edge.title}
									/>
									<span className='nav-item'>{edge.title}</span>
								</a>
							</li>
						))}
					</ul>
					<a
						href={edges[0].node.items[edges[0].node.items.length - 1].href}
						onClick={handleClick}
						className='fixed flex flex-row pt-2 pb-4 pl-10 pr-4 bottom-4 justify-items-center text-slate-100'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-6 h-6'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3'
							/>
						</svg>

						<span className='nav-item text-slate-100'>Scroll Down</span>
					</a>
				</nav>
			</aside>
		</>
	);
};

export default SideBar;
