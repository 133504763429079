import '../sass/global.scss';

import { IntlShape, injectIntl } from 'gatsby-plugin-intl-v4';
import Modal, { ModalState } from './Modal';
import React, { ReactNode } from 'react';

import { HeadFC } from 'gatsby';
import { Helmet } from 'react-helmet';
import Language from './Language';
import { SEO } from './SEO';
import SideBar from './SideBar';
import { modalState } from '../state';
import { useRecoilState } from 'recoil';

type LayoutProps = {
	edges: [{ node: any }];
	languages: [];
	children: ReactNode;
	intl: IntlShape;
};
const Layout = ({ languages, edges, children, intl }: LayoutProps) => {
	const [modal, setModal] = useRecoilState(modalState);

	const handleCloseModal = () => {
		setModal({
			...modal,
			email: { visible: false, state: ModalState.SUCCESS },
		});
	};

	return (
		<>
			<Helmet htmlAttributes={{ lang: intl.locale }} />
			<SideBar edges={edges} />
			<header className='flex flex-row justify-end end'>
				<Language slugs={languages} />
			</header>
			<Modal
				isVisible={modal.email.visible}
				type={modal.email.state}
				onClickClose={handleCloseModal}
			/>
			<main className='container pl-4 pr-4 mx-auto'>{children}</main>
		</>
	);
};
export default injectIntl(Layout);
export const Head: HeadFC = ({
	pageContext: { slug, title, description },
}: any) => <SEO title={title} description={description} pathname={slug} />;
