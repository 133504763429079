import React, { useState } from 'react';

import ContentFulInput from './ContentFulInput';
import { ModalState } from './Modal';
import { modalState } from '../state';
import { useRecoilState } from 'recoil';

type ContentFulFormProps = {
	items: [
		{
			isRequired: boolean;
			label: string;
			placeholder: string;
			slug: string;
			type: string;
		}
	];
};

const ContentFulForm = ({ items }: ContentFulFormProps) => {
	const [isWaiting, setIsWaiting] = useState(false);
	const [modal, setModal] = useRecoilState(modalState);
	const handleSubmit = async (event) => {
		event.preventDefault();
		const response = await sendEmail({
			email: event.target.email.value,
			message: event.target.message.value,
			subject: event.target.subject.value,
			name: event.target.name.value,
		});
		if (response.statusCode === 200) {
			event.target.reset();
			setModal({
				...modal,
				email: { visible: true, state: ModalState.SUCCESS },
			});
		} else {
			setModal({
				...modal,
				email: { visible: true, state: ModalState.ERROR },
			});
		}
		setIsWaiting(false);
	};

	const sendEmail = async (formData) => {
		setIsWaiting(true);
		const rawResponse = await fetch('https://api.gianmatteo-spoto.be/contact', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		});
		return await rawResponse.json();
	};
	return (
		<form onSubmit={handleSubmit}>
			<div className='flex flex-row'>
				<ContentFulInput
					className='mr-4'
					data={items.filter((item) => item.slug === 'name').shift()!}
				/>
				<ContentFulInput
					data={items.filter((item) => item.slug === 'email').shift()!}
				/>
			</div>
			{items
				.filter(
					(item) =>
						item.slug !== 'email' &&
						item.slug !== 'name' &&
						item.slug !== 'submit'
				)
				.map((item) => (
					<ContentFulInput data={item} />
				))}
			<ContentFulInput
				isDisabled={isWaiting}
				isWaiting={isWaiting}
				data={items.filter((item) => item.slug === 'submit').shift()!}
			/>
		</form>
	);
};

export default ContentFulForm;
