import ContentFulLink, { ContentFulLinkProps } from './ContentFulLink';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import React from 'react';

type ContentFulProjectProps = {
	content: {
		title: string;
		features: string[];
		technologies: { title }[];
		link: ContentFulLinkProps;
		screenshot: any;
	};
};
const ContentFulProject = ({ content }: ContentFulProjectProps) => {
	const image = getImage(content.screenshot) as IGatsbyImageData;
	return (
		<article className='grid grid-cols-12 mb-4'>
			<div className='col-span-12 mb-4 text-center lg:text-left lg:mb-0 lg:mr-10 lg:col-span-4'>
				<GatsbyImage
					className='rounded-lg'
					image={image}
					alt={content.screenshot.filename}
				/>
			</div>
			<div
				data-sal='slide-left'
				data-sal-delay='1000'
				data-sal-easing='ease'
				className='col-span-12 lg:col-span-8 '
			>
				<h4>{content.title}</h4>
				<div className='flex flex-row flex-wrap'>
					<span className='mr-4'>Technologies Used:</span>
					<ul className='flex flex-row flex-wrap mb-4 '>
						{content.technologies.map(({ title }, index) => (
							<li className='mr-2 text-sky-500' key={title}>
								<span>{title}&#160;</span>
								{content.technologies.length - 1 !== index ? (
									<span>&#44;</span>
								) : (
									<span>&#160;</span>
								)}
							</li>
						))}
					</ul>
				</div>

				<ul className='mb-10'>
					{content.features.map((feature) => (
						<li key={feature}>&#8226;&nbsp;{feature}</li>
					))}
				</ul>
				<ContentFulLink className='text-sky-500' href={content.link.href}>
					{content.link.title}
				</ContentFulLink>
			</div>
		</article>
	);
};

export default ContentFulProject;
