import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl-v4';

import React from 'react';
import { graphql } from 'gatsby';

const languageName = {
	en: 'English',
	fr: 'Français',
};

const Language = ({ slugs }) => (
	<div>
		<IntlContextConsumer>
			{({
				languages,
				language: currentLocale,
			}: {
				languages: string[];
				language: string;
			}) =>
				languages.map((language: string) => (
					<span
						key={language}
						onClick={() =>
							changeLocale(
								language,
								`/${
									slugs
										.filter(
											(slug: { slug: string; node_locale: string }) =>
												slug.node_locale === language
										)
										.shift().slug
								}`
							)
						}
						className={`${
							currentLocale === language ? 'text-sky-400' : 'text-slate-400'
						}`}
						style={{
							margin: 10,
							textDecoration: `underline`,
							cursor: `pointer`,
						}}
					>
						{languageName[language]}
					</span>
				))
			}
		</IntlContextConsumer>
	</div>
);

export default Language;
