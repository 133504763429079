import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import React from 'react';

type ContentFulMediaProps = {
	className?: string;
	source: { title: string; file: { url: string; contentType: string } };
};
const ContentFulMedia = ({ source, className }: ContentFulMediaProps) => {
	const renderWidget = () => {
		if (source.file.contentType === 'application/pdf') {
			return (
				<a href={source.file.url} target='_blank'>
					{source.title}
				</a>
			);
		} else {
			const image = getImage(source) as IGatsbyImageData;
			return <GatsbyImage image={image} alt={source.title} />;
		}
	};
	return <div className={className}>{renderWidget()}</div>;
};

export default ContentFulMedia;
