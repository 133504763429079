import React from 'react';
type ContentFulSkillProps = {
	content: {
		title: string;
		icon: { title: string; file: { url: string } };
		rating: number;
	}[];
};
const ContentFulSkill = ({ content }: ContentFulSkillProps) => {
	return (
		<div className='flex flex-row flex-wrap'>
			{content.map((item, index) => (
				<div
					key={item.title}
					data-sal='slide-left'
					data-sal-delay={(500 * (index + 1)).toString()}
					data-sal-easing='ease'
					className={`w-40 h-48 flex flex-col items-center justify-center mb-4
					 pt-2 pb-2 pl-4 pr-4 ${
							content.length - 1 != index ? 'mr-16' : ''
						} shadow-xl hover:shadow-sky-500/50 item s-center rounded-xl bg-slate-400/10`}
				>
					<img
						className='w-20 h-20 mb-2'
						src={item.icon.file.url}
						alt={item.title}
					/>
					<div className='mb-4 text-sm text-center text-slate-100'>
						{item.title}
					</div>
					<ul className='flex flex-row flex-wrap'>
						{Array(item.rating)
							.fill(0)
							.map((value, index) => (
								<li key={index}>
									<svg
										color='#0ea5e9'
										xmlns='http://www.w3.org/2000/svg'
										fill='#0ea5e9'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
										/>
									</svg>
								</li>
							))}
					</ul>
				</div>
			))}
		</div>
	);
};

export default ContentFulSkill;
