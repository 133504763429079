import {
	ContentfulRichTextGatsbyReference,
	RenderRichTextData,
	renderRichText,
} from 'gatsby-source-contentful/rich-text';

import React from 'react';

type ContentFulParagrapheProps = {
	content: RenderRichTextData<ContentfulRichTextGatsbyReference>;
	title: string;
};
const ContentFulParagraphe = ({
	content,
	title,
}: ContentFulParagrapheProps) => {
	return (
		<div>
			<h3>{title}</h3>
			{content ? renderRichText(content) : null}
		</div>
	);
};

export default ContentFulParagraphe;
