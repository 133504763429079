import React from 'react';
type ContentFullInputProps = {
	className?: string;
	data: {
		isRequired: boolean;
		label: string;
		placeholder: string;
		slug: string;
		type: string;
	};
	isDisabled?: boolean;
	isWaiting?: boolean;
};

const ContentFulInput = ({
	className,
	data,
	isDisabled = false,
	isWaiting = false,
}: ContentFullInputProps) => {
	return (
		<div
			className={`w-full mx-auto pb-4 ${className} flex flex-row justify-center`}
		>
			<div
				className={`${isDisabled ? 'opacity-50 pointer-events-none' : ''} ${
					data.type === 'submit'
						? 'cursor-pointer  flex items-center mt-4 pt-2 pb-2 pl-8 pr-8 font-medium rounded-full hover:shadow-lg hover:shadow-sky-500/50 bg-sky-400/10  lg:text-sm lg:leading-6 text-slate-400 hover:text-slate-300 hover:bg-sky-500'
						: `bg-slate-400/10 rounded-lg  w-full`
				}`}
			>
				{isWaiting && (
					<div className='mr-4 lds-ring'>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				)}
				<input
					type={data.type}
					value={data.placeholder}
					placeholder={data.placeholder}
					name={data.slug}
					required={data.isRequired}
					disabled={isDisabled}
					className={`w-full h-full ${
						data.type === 'submit' ? 'cursor-pointer' : 'p-4'
					} bg-transparent`}
				/>
			</div>
		</div>
	);
};

export default ContentFulInput;
