import { FormattedMessage, IntlShape, injectIntl } from 'gatsby-plugin-intl-v4';

import React from 'react';

type ModalProps = {
	isVisible?: boolean;
	type: ModalState;
	onClickClose: () => void;
	intl: IntlShape;
};

export enum ModalState {
	SUCCESS = 'success',
	ERROR = 'error',
}
const Modal = ({ isVisible, type, onClickClose }: ModalProps) => {
	return (
		<>
			{isVisible && (
				<div className='fixed z-10 flex flex-col items-center justify-center w-full h-full p-4'>
					<div className='flex flex-col items-center justify-center w-full p-4 shadow-2xl md:w-96 rounded-3xl bg-slate-600 '>
						{type === ModalState.ERROR && (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-20 h-20 mb-4 text-red-500'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
								/>
							</svg>
						)}
						{type === ModalState.SUCCESS && (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-20 h-20 mb-4 text-green-500'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
						)}
						<h2 className='mb-4 text-2xl'>
							<FormattedMessage id={`modal.${type}.email.title`} />
						</h2>
						<p className='mb-4 text-justify'>
							<FormattedMessage id={`modal.${type}.email.content`} />
						</p>

						<button
							className='flex items-center pt-2 pb-2 pl-8 pr-8 mx-auto mt-4 font-medium rounded-full cursor-pointer hover:shadow-lg hover:shadow-sky-500/50 bg-sky-400/10 lg:text-sm lg:leading-6 text-slate-400 hover:text-slate-300 hover:bg-sky-500'
							onClick={onClickClose}
						>
							<FormattedMessage id='close' />
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default injectIntl(Modal);
